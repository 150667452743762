import React from 'react'
import styled from 'styled-components'

import { viewport } from '../../constants/viewport'

interface PageContentProps {
  children: React.ReactElement | React.ReactElement[]
}

const desktopContentWith = 1140
const tabletContentWith = 720
const mobileContentWith = 355

const PageContent = styled.div<PageContentProps>`
  margin: 0 auto;

  @media (min-width: ${viewport.desktop}px) {
    max-width: ${desktopContentWith}px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    max-width: ${tabletContentWith}px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    max-width: ${mobileContentWith}px;
  }
`

export default PageContent
