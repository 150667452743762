import { Link as _Link } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import styled from 'styled-components'

import CardBackground from '../../assets/images/card_backGround.jpg'
import MapIcon from '../../assets/images/maps.jpg'
import { space } from '../../constants/length'
import { Primary, White } from '../../constants/newColor'
import { Caption, H2 as _H2 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'

interface ColorProps {
  color: string
}

type LocationProps = ColorProps & {
  logoimg?: string
  index?: number
  length?: number
  hassite: boolean
}
const Wrapper = styled(_Link)<LocationProps>`
  width: 100%;
  display: flex;
  border: 1px solid ${Primary};
  background-color: ${White};
  margin-bottom: ${props => (props.index === props.length ? '0px' : '24px')};

  border-radius: 5px;
  padding: ${space.m}px;
  position: relative;

  cursor: ${props => (props.hassite ? 'pointer' : 'default')};
  &::before {
    content: '';
    background: url(${props => props.logoimg}) center no-repeat;
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
  }
  &::after {
    content: '';
    width: 31px;
    height: 31px;
    position: absolute;
    border-radius: 0 0 ${space.xs}px 0;
    bottom: 0;
    right: 0;
    background-image: url(${CardBackground});
    background-color: ${props => props.color};
    background-blend-mode: lighten;
    background-size: 31px 31px;
  }

  @media (min-width: ${viewport.tablet}px) {
    max-width: 464px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    max-width: 322px;
  }
`

const Info = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  place-items: start;
  padding-left: ${space.xl}px;
`
interface H2Props {
  isHovering: boolean
  color: string
}
const H2 = styled.h2<H2Props>`
${_H2}
  color: ${props => (props.isHovering ? props.color : Primary)};
  text-align: left;
`
const Map = styled.div<ColorProps>`
  height: ${space.m}px;
  width: ${space.m}px;
  margin-right: ${space.s}px;
  justify-self: center;
  background-image: url(${MapIcon});
  background-color: ${props => props.color};
  background-blend-mode: lighten;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`
const Address = styled.div`
  display: flex;
  align-items: flex-start;
`
const AddressText = styled.span`
  ${Caption}
  text-align: left;
  @media (max-width: ${viewport.tablet - 1}px) {
    max-width: 150px;
  }
`

interface BlurProps {
  primaryColor?: string
}
const Blur = styled.div<BlurProps>`
  ${_H2}
  width: 100%;
  height: 100%;
  color: ${White};
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;

  backdrop-filter: blur(10px);
  border-radius: ${space.xs}px;
  background-color: ${props => props.primaryColor}70;
  z-index: 9;
`

export interface ClinicCardProps {
  index?: number
  length?: number
  title?: string
  name?: string
  primaryColor?: string
  logoImageUrl?: string
  address?: string
  hassite: boolean
}
const ClinicCard = (props: ClinicCardProps) => {
  const { title, name, primaryColor, logoImageUrl, address, index, length, hassite = false } = props
  const [hovering, setHovering] = useState(false)

  return (
    <Wrapper
      to={hassite ? `/clinic/${title}` : '/'}
      color={primaryColor || '#ad5b9c'}
      onMouseEnter={() => hassite && setHovering(true)}
      onMouseLeave={() => hassite && setHovering(false)}
      logoimg={logoImageUrl}
      index={index}
      length={length}
      hassite={hassite}
    >
      {!hassite && (
        <Blur primaryColor={primaryColor}>
          準備中
          <br /> 敬請期待
        </Blur>
      )}
      <Info>
        <H2 isHovering={hovering} color={primaryColor || '#ad5b9c'}>
          {name}
        </H2>
        <Address>
          <Map color={primaryColor || '#ad5b9c'} />
          <AddressText>{address}</AddressText>
        </Address>
      </Info>
    </Wrapper>
  )
}

export default ClinicCard
