import React from 'react'
import styled from 'styled-components'

import { Primary } from '../../constants/newColor'
import { viewport } from '../../constants/viewport'
import { PageTitle, PageTitleProps } from '../Titles/PageTitle'

const PageBannerWrapper = styled.div<{ imageUrl: string }>`
  width: 100%;
  @media (min-width: ${viewport.desktop}px) {
    height: 250px;
  }
  @media (max-width: ${viewport.desktop - 1}px) and (min-width: ${viewport.tablet}px) {
    height: 186px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    height: 169px;
  }

  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 65%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${Primary};
    opacity: 0.3;
  }
`

const TitleLift = styled.div`
  z-index: 0;
`

interface PageBannerProps extends PageTitleProps {
  imageUrl: string
}

const PageBanner = (props: PageBannerProps) => {
  const { imageUrl, title, h4title, subtitle } = props
  return (
    <PageBannerWrapper imageUrl={imageUrl}>
      <TitleLift>
        <PageTitle title={title} h4title={h4title} subtitle={subtitle} />
      </TitleLift>
    </PageBannerWrapper>
  )
}

export default PageBanner
