import styled from 'styled-components'

import { Primary } from '../../constants/newColor'
import { viewport } from '../../constants/viewport'

const ContentTitle = styled.h2`
  padding-bottom: 8px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: ${Primary};
  border-bottom: 1px solid ${Primary};
  @media (min-width: ${viewport.tablet}px) {
    /* @todo 等 textSize 調整後統一修改 */
    font-size: 30px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    /* @todo 等 textSize 調整後統一修改 */
    font-size: 24px;
  }
`
const ClientPageContentTitle = styled.h4`
  padding-bottom: 8px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: ${Primary};
  border-bottom: 1px solid ${Primary};
  @media (min-width: ${viewport.tablet}px) {
    /* @todo 等 textSize 調整後統一修改 */
    font-size: 30px;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    /* @todo 等 textSize 調整後統一修改 */
    font-size: 24px;
  }
`

export { ContentTitle, ClientPageContentTitle }
