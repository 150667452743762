import React from 'react'
import styled from 'styled-components'

import { LightText, White } from '../../constants/color'
import { space } from '../../constants/length'
import { viewport } from '../../constants/viewport'

const Wrap = styled.div`
  font-family: 'Noto Sans TC';
  display: inline-block;
  text-align: center;
  color: ${White};
`

const TitleWrapper = styled.h1`
  font-size: 40px;
  color: ${White};

  &::after {
    content: '';
    display: block;
    width: 50%;
    margin: 0 auto;
    padding-top: ${space.m}px;
    border-bottom: 1px solid ${LightText};
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    font-size: 24px;
    &::after {
      padding-top: ${space.xs}px;
    }
  }
`
const ClientPageTitle = styled.h4`
  font-size: 40px;
  color: ${White};

  &::after {
    content: '';
    display: block;
    width: 50%;
    margin: 0 auto;
    padding-top: ${space.m}px;
    border-bottom: 1px solid ${White};
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    font-size: 24px;
    &::after {
      padding-top: ${space.xs}px;
    }
  }
`
const SubtitleWrapper = styled.div`
  font-family: 'cwTeXYen';
  font-size: 24px;
  margin-top: 12px;
  letter-spacing: 1.3px;

  @media (max-width: ${viewport.tablet - 1}px) {
    margin-top: ${space.xs}px;
    font-size: 16px;
  }
`

export interface PageTitleProps {
  title?: string
  h4title?: string
  subtitle: string
}

/**
 * @note font-size 與 font-family 屬於特例，不使用 constant variable
 */
const PageTitle = (props: PageTitleProps) => {
  const { title, h4title, subtitle } = props
  return (
    <Wrap>
      {title && <TitleWrapper>{title}</TitleWrapper>}
      {h4title && <ClientPageTitle>{h4title}</ClientPageTitle>}
      <SubtitleWrapper>{subtitle}</SubtitleWrapper>
    </Wrap>
  )
}

export { PageTitle }
